import React, { useState, useEffect } from "react"
import { 
   Box, 
   Link, 
   Text, 
   Image,
   Center,
   Tooltip,
   Skeleton,
   Spacer,
   Button,
   Wrap,
   WrapItem,
   Switch,
   Spinner,
   Select,
   AspectRatio
} from "@chakra-ui/react"
import { Link as GatsbyLink } from 'gatsby'
import { 
   BiSort
} from "react-icons/bi"
import axios  from 'axios'
import Layout from '../components/layout.js';
import {
   getWindowParam,
   num2ja,
   Midasi2,
   Midasi3,
   RadioButtonsC5v1,
   Pagenavi2
} from '../util/functions.js';


const VideoList = (pageprops) => {
   const [params,setParams] = useState({
      keyword:"",
      category:"全て",
      duration:"全期間",
      dateOrViewOrRegist:"日付",
      vtu:0,
      vtd:0,
      ltu:0,
      ltd:0,
      ctu:0,
      ctd:0,
      page:1,
   });
   const [pager,setPager] = useState({
      page:1,
      isFirstpage:true,
      isEndpage:false
   })
   const [dbhitsCount,setDbhitsCount] = useState({
      dbhitsCount:0,
      pagecounts:0,
      finished:false
   });
   const [selectedVideoLists,setselectedVideoLists] = useState({
      selectedVideoLists:[],
      finished:false,
   });
   const [videoMostPopuler,setvideoMostPopuler] =useState({
      list:[],
      finished:false,
   })
   const [kanrenWords,setkanrenWords] = useState({
      kanrenWords:[],
      finished:false
   });
   const [ownkanrenWords,setownkanrenWords] = useState({
      kanrenWords:[],
      finished:false
   });
   const [isDisabled,setisDisabled] = useState(true);
   const [isMostPopuler,setisMostPopuler] = useState(false);
   const [isaddkeyword,setisaddkeyword] = useState(false);
   const [kanrenPosts,setkanrenPosts] = useState({
      kanrenPosts:[],
      finished:false
   });

   const getDBHistsVideoCount = (param) => {
      setDbhitsCount({
         dbhitsCount:0,
         pagecounts:0,
         finished:false
      })
      var type="getDBHistsVideoCount";
      let postdata = Object.assign({type:type}, param);
      axios.post('https://ytsearch-wordpress.yt-search.net/api/', postdata)
         .then( (res) => {
            console.log(res);
            setDbhitsCount({
               dbhitsCount:res.data.hitsCount,
               pagecounts:Math.ceil(res.data.hitsCount / 10),
               finished:true,
            })
         })
         .catch( (error) => {
            console.log(error);
            setDbhitsCount({
               dbhitsCount:0,
               pagecounts:0,
               finished:true,
            })
      });
   }

   const getVideoListsOnePage = (param) => {
      setselectedVideoLists({
         selectedVideoLists:[],
         finished:false,
      });
      var type="getVideoListsOnePage";
      let postdata = Object.assign({type:type}, param);
      axios.post('https://ytsearch-wordpress.yt-search.net/api/', postdata)
         .then( (res) => {
            console.log(res.data);
            setselectedVideoLists({
               selectedVideoLists:res.data.videolists,
               finished:true,
            });
         })
         .catch( (error) => {
            console.log(error);
            setselectedVideoLists({
               selectedVideoLists:[],
               finished:true,
            });
      });
   }

   const getVideoMostPopuler = (param) => {
      setselectedVideoLists({
         selectedVideoLists:[],
         finished:false,
      });
      var type="getVideoMostPopuler";
      let postdata = Object.assign({type:type}, param);
      axios.post('https://ytsearch-wordpress.yt-search.net/api/', postdata)
         .then( (res) => {
            // console.log(res.data);
            setvideoMostPopuler({
               list : res.data.youtubeVideoLists,
               finished:true
            });
            setselectedVideoLists({
               selectedVideoLists:res.data.youtubeVideoLists.slice(0,10),
               finished:true,
            });
         })
         .catch( (error) => {
            console.log(error);
            setvideoMostPopuler ({
               list : [],
               finished:true
            });
            setselectedVideoLists({
               selectedVideoLists:[],
               finished:true,
            });
      });
   }

   const getVideoKanrenWords = (param) => {
      setkanrenWords({
         kanrenWords:[],
         finished:false
      });
      var type="getVideoKanrenWords";
      let postdata = Object.assign({type:type}, param);
      axios.post('https://ytsearch-wordpress.yt-search.net/api/', postdata)
         .then( (res) => {
            // console.log(res.data);
            var pairs = Object.entries(res.data.wordresult);
            pairs.sort(function(p1, p2){
               var p1Val = p1[1], p2Val = p2[1];
               return p2Val - p1Val;
            })
            setkanrenWords({
               kanrenWords:pairs,
               finished:true
            });
         })
         .catch( (error) => {
            console.log(error);
            setkanrenWords({
               kanrenWords:[],
               finished:true
            });
      });
   }

   const getVideoOwnKanrenWords = (param) => {
      setownkanrenWords({
         kanrenWords:[],
         finished:false
      });
      var type="getVideoOwnKanrenWords";
      let postdata = Object.assign({type:type}, param);
      axios.post('https://ytsearch-wordpress.yt-search.net/api/', postdata)
         .then( (res) => {
            console.log(res.data);
            setownkanrenWords({
               kanrenWords:res.data.datas,
               finished:true
            });
         })
         .catch( (error) => {
            console.log(error);
            setownkanrenWords({
               kanrenWords:[],
               finished:true
            });
      });
   }
   const getVideoKanrenPosts = (param) => {
      setkanrenPosts({
         kanrenPosts:[],
         finished:false
      });
      var type="getVideoKanrenPosts";
      let postdata = Object.assign({type:type}, param);
      axios.post('https://ytsearch-wordpress.yt-search.net/api/', postdata)
         .then( (res) => {
            // console.log(res);
            setkanrenPosts({
               kanrenPosts:res.data.postresult,
               finished:true
            });
         })
         .catch( (error) => {
            console.log(error);
            setkanrenPosts({
               kanrenPosts:[],
               finished:true
            });
      });
   }

   const reflash = (rettemp) => {
      // console.log(rettemp);
      setParams(rettemp);
      if(rettemp.category === "急上昇"){
         setselectedVideoLists({
            selectedVideoLists:[],
            finished:false,
         });
         setselectedVideoLists({
            selectedVideoLists:videoMostPopuler.list.slice((rettemp.page-1)*10,rettemp.page*10),
            finished:true,
         });
         
      }else{
         getVideoKanrenWords(rettemp);
         getVideoOwnKanrenWords(rettemp);
         getVideoListsOnePage(rettemp);
         getVideoKanrenPosts(rettemp);
      }
   }

   const reflash1 = (rettemp) => {
      // console.log(rettemp);
      setParams(rettemp);
      if(rettemp.category === "急上昇"){
         setselectedVideoLists({
            selectedVideoLists:[],
            finished:false,
         });
         setselectedVideoLists({
            selectedVideoLists:videoMostPopuler.list.slice((rettemp.page-1)*10,rettemp.page*10),
            finished:true,
         });
         
      }else{
         getVideoKanrenWords(rettemp);
         getVideoOwnKanrenWords(rettemp);
         getVideoListsOnePage(rettemp);
         getVideoKanrenPosts(rettemp);
         getDBHistsVideoCount(rettemp);
      }
   }

   useEffect(() =>{
      let temp = getWindowParam(params);
      setParams(temp)
      if(temp.category === "急上昇"){
         setisMostPopuler(true);
         getVideoMostPopuler(temp);
         setDbhitsCount({
            dbhitsCount:50,
            pagecounts:5,
            finished:true,
         })
         setkanrenWords({
            kanrenWords:[],
            finished:true
         });
      }else{
         getDBHistsVideoCount(temp);
         
         reflash(temp);
      };
      

   },[]);

   return (
      <Layout 
         // title={"ビデオランキング"}
         // description={"YouTubeビデオランキングを掲載しています"}
         title={"ビデオ"}
         description={"YouTubeビデオを掲載しています"}
         // image={}
         uri={pageprops.uri}
         canonicalUri={"/videolist"}
      >
         <Midasi2>ビデオ一覧</Midasi2>

         <Box bg="#ddd" mx="0.75rem" p="1rem">
            <Box>
               キーワード:
               {
                  params.keyword==="" ?
                     "全て "
                  :
                     React.Children.toArray(params.keyword.split(/\s+/).map((val) => {
                        return (
                           val + " "
                        )
                     }))
               }
            </Box>

            <Box>
               カテゴリ:{params.category}
            </Box>
            <Box>再生回数:  {num2ja(params.vtd)} ～ {params.vtu==0?"∞":num2ja(params.vtu)}</Box>
            <Box>ライク数:  {num2ja(params.ltd)} ～ {params.ltu==0?"∞":num2ja(params.ltu)}</Box>
            <Box>コメント数:  {num2ja(params.ctd)} ～ {params.ctu==0?"∞":num2ja(params.ctu)}</Box>
         </Box>

         <Box mx="0.75rem" mt="1rem">
            {/**
            <Select 
               isDisabled = {isMostPopuler}
               my="0.5rem" 
               icon={<BiSort />} 
               bg="#fff" 
               fontSize="0.9rem" 
               fontWeight="bold"
               onChange={(e) => {
                  reflash({
                     ...params,
                     dateOrViewOrRegist:e.target.value,
                  });
                  if(e.target.value === "日付" || e.target.value === "日付s"){
                     setisDisabled(true);
                  }else{
                     setisDisabled(false);
                  }
               }}
               // onChange={(e) => {selectViewOrRegistChange(e.target.value)}}
            >
               <option value="日付">日付順</option>
               <option value="日付s">日付順（昇順）</option>
               <option value="再生回数">再生回数順</option>
               <option value="再生回数s">再生回数順（昇順）</option>
               <option value="ライク数">ライク順</option>
               <option value="ライク数s">ライク順（昇順）</option>
               <option value="コメント数">コメント順</option>
               <option value="コメント数s">コメント順（昇順）</option>
            </Select>
            */}
            {/** 
            <RadioButtonsC5v1 
               name="selectDuration"
               defaultValue={"全期間"}
               handleclick={(e) => {
                  reflash({
                     ...params,
                     duration:e,
                  });
               }}
               // handleclick={selectDurationChange}
               items={["全期間","日間","週間","月間"]}
               isDisabled={isDisabled}
            />
            */}

         </Box>

         <Box bg="#fff" mx="0.75rem">
         
         {/* {console.log(selectedVideoLists)} */}
         {
         selectedVideoLists.finished ?
            selectedVideoLists.selectedVideoLists.length > 0 ?
               <>
               <Box pt="0.4rem">
                  {
                  React.Children.toArray(selectedVideoLists.selectedVideoLists.map((val,index)=>{
                     return (
                        <>
                        <Box borderBottom="1px solid #e0e0e0" px="1rem" pb="0.5rem">

                           <Box mt="1rem">
                              <Link
                                 ml="1rem"
                                 // as={GatsbyLink} 
                                 href={isMostPopuler?
                                    //別にした方がよい
                                       "https://www.youtube.com/watch?v=" + val.videoId
                                    :
                                       pageprops.location.origin + "/videodetail" + "?vi=" + val.videoId
                                    }
                                 fontWeight={"bold"}
                                 fontSize="1.1rem"
                                 lineHeight="1.4rem"
                                 isExternal
                              >
                                 {val.videoTitle}
                              </Link>
                           </Box>

                           <Box p="0.5rem" display={["block","flex"]}>
                              <Center px="1.5rem">
                                 <Image
                                    src={val.trimedvi}
                                    alt={val.videoTitle}
                                    w="100%"
                                 />
                              </Center>
                              
                              <Box fontSize="0.8rem" mt="0.5rem" w="100%">
                                 <Wrap spacing="0" borderBottom="1px #d9d9d9b8 solid">
                                    <WrapItem>投稿日 : </WrapItem>
                                    <Spacer />
                                    <WrapItem>{val.publishedAt}</WrapItem>
                                 </Wrap>
                                 <Wrap spacing="0" mt="0.3rem" borderBottom="1px #d9d9d9b8 solid">
                                    <WrapItem>再生回数 : </WrapItem>
                                    <Spacer />
                                    <WrapItem>{num2ja(val.viewsCount)}回</WrapItem>
                                 </Wrap>
                                 <Wrap spacing="0" mt="0.3rem" borderBottom="1px #d9d9d9b8 solid">
                                    <WrapItem>ライク数 : </WrapItem>
                                    <Spacer />
                                    <WrapItem>{num2ja(val.likeCount)}回</WrapItem>
                                 </Wrap>
                                 <Wrap spacing="0" mt="0.3rem" borderBottom="1px #d9d9d9b8 solid">
                                    <WrapItem>コメント数 : </WrapItem>
                                    <Spacer />
                                    <WrapItem>{num2ja(val.commentCount)}回</WrapItem>
                                 </Wrap>
                                 {
                                    params.category !== "急上昇" &&
                                    {
                                       "全期間":
                                          <>
                                          </>
                                          ,
                                       "日間":
                                          <>
                                          <Wrap spacing="0" mt="0.3rem" borderBottom="1px #d9d9d9b8 solid">
                                             <WrapItem>日間再生回数 : </WrapItem>
                                             <Spacer />
                                             <WrapItem>{num2ja(val.viewsCountDayDiff)}回</WrapItem>
                                          </Wrap>
                                          <Wrap spacing="0" mt="0.3rem" borderBottom="1px #d9d9d9b8 solid">
                                             <WrapItem>日間ライク数 : </WrapItem>
                                             <Spacer />
                                             <WrapItem>{num2ja(val.likeCountDayDiff)}回</WrapItem>
                                          </Wrap>
                                          <Wrap spacing="0" mt="0.3rem" borderBottom="1px #d9d9d9b8 solid">
                                             <WrapItem>日間コメント数 : </WrapItem>
                                             <Spacer />
                                             <WrapItem>{num2ja(val.commentCountDayDiff)}回</WrapItem>
                                          </Wrap>
                                          <Wrap spacing="0" mt="0.3rem" borderBottom="1px #d9d9d9b8 solid">
                                             <WrapItem>日間比較日付 : </WrapItem>
                                             <Spacer />
                                             <WrapItem>{val.dayDiffTarget}</WrapItem>
                                          </Wrap>
                                          </>
                                          ,
                                       "週間":
                                          <>
                                          <Wrap spacing="0" mt="0.3rem" borderBottom="1px #d9d9d9b8 solid">
                                             <WrapItem>週間再生回数 : </WrapItem>
                                             <Spacer />
                                             <WrapItem>{num2ja(val.viewsCountWeekDiff)}回</WrapItem>
                                          </Wrap>
                                          <Wrap spacing="0" mt="0.3rem" borderBottom="1px #d9d9d9b8 solid">
                                             <WrapItem>週間ライク数 : </WrapItem>
                                             <Spacer />
                                             <WrapItem>{num2ja(val.likeCountWeekDiff)}回</WrapItem>
                                          </Wrap>
                                          <Wrap spacing="0" mt="0.3rem" borderBottom="1px #d9d9d9b8 solid">
                                             <WrapItem>週間コメント数 : </WrapItem>
                                             <Spacer />
                                             <WrapItem>{num2ja(val.commentCountWeekDiff)}回</WrapItem>
                                          </Wrap>
                                          <Wrap spacing="0" mt="0.3rem" borderBottom="1px #d9d9d9b8 solid">
                                             <WrapItem>週間比較日付 : </WrapItem>
                                             <Spacer />
                                             <WrapItem>{val.weekDiffTarget}</WrapItem>
                                          </Wrap>
                                          </>
                                          ,
                                       "月間":
                                          <>
                                          <Wrap spacing="0" mt="0.3rem" borderBottom="1px #d9d9d9b8 solid">
                                             <WrapItem>月間再生回数 : </WrapItem>
                                             <Spacer />
                                             <WrapItem>{num2ja(val.viewsCountMonthDiff)}回</WrapItem>
                                          </Wrap>
                                          <Wrap spacing="0" mt="0.3rem" borderBottom="1px #d9d9d9b8 solid">
                                             <WrapItem>月間ライク数 : </WrapItem>
                                             <Spacer />
                                             <WrapItem>{num2ja(val.likeCountMonthDiff)}回</WrapItem>
                                          </Wrap>
                                          <Wrap spacing="0" mt="0.3rem" borderBottom="1px #d9d9d9b8 solid">
                                             <WrapItem>月間コメント数 : </WrapItem>
                                             <Spacer />
                                             <WrapItem>{num2ja(val.commentCountMonthDiff)}回</WrapItem>
                                          </Wrap>
                                          <Wrap spacing="0" mt="0.3rem" borderBottom="1px #d9d9d9b8 solid">
                                             <WrapItem>月間比較日付 : </WrapItem>
                                             <Spacer />
                                             <WrapItem>{val.monthDiffTarget}</WrapItem>
                                          </Wrap>
                                          </>
                                          ,
                                    }[params.duration]
                                 }
                                 <Wrap spacing="0" mt="0.3rem" borderBottom="1px #d9d9d9b8 solid">
                                    <WrapItem>チャンネル名 : </WrapItem>
                                    <Spacer />
                                    <WrapItem>
                                       <Link
                                          // as={GatsbyLink} 
                                          href={pageprops.location.origin + "/channeldetail"
                                             + "?ch=" + val.channelId}
                                          isExternal
                                          color="#428cc1"
                                          textDecoration="underline"
                                          fontWeight="700"
                                       >
                                             {val.channelTitle}
                                       </Link>
                                    </WrapItem>
                                 </Wrap>

                                 {/* <Wrap spacing="0" borderBottom="1px #d9d9d9b8 solid">
                                    <WrapItem>投稿日 : </WrapItem>
                                    <Spacer />
                                    <WrapItem>{val.publishedAt}</WrapItem>
                                 </Wrap>
                                 <Wrap spacing="0" mt="0.3rem" borderBottom="1px #d9d9d9b8 solid">
                                    <WrapItem>再生回数 : </WrapItem>
                                    <Spacer />
                                    <WrapItem>{num2ja(val.viewsCount)}回</WrapItem>
                                 </Wrap>
                                 <Wrap spacing="0" mt="0.3rem" borderBottom="1px #d9d9d9b8 solid">
                                    <WrapItem>ライク数 : </WrapItem>
                                    <Spacer />
                                    <WrapItem>{num2ja(val.likeCount)}回</WrapItem>
                                 </Wrap>
                                 <Wrap spacing="0" mt="0.3rem" borderBottom="1px #d9d9d9b8 solid">
                                    <WrapItem>コメント数 : </WrapItem>
                                    <Spacer />
                                    <WrapItem>{num2ja(val.commentCount)}回</WrapItem>
                                 </Wrap>
                                 <Wrap spacing="0" mt="0.3rem" borderBottom="1px #d9d9d9b8 solid">
                                    <WrapItem>日間再生回数 : </WrapItem>
                                    <Spacer />
                                    <WrapItem>{num2ja(val.viewsCountDayDiff)}回</WrapItem>
                                 </Wrap>
                                 <Wrap spacing="0" mt="0.3rem" borderBottom="1px #d9d9d9b8 solid">
                                    <WrapItem>週間再生回数 : </WrapItem>
                                    <Spacer />
                                    <WrapItem>{num2ja(val.viewsCountWeekDiff)}回</WrapItem>
                                 </Wrap>
                                 <Wrap spacing="0" mt="0.3rem" borderBottom="1px #d9d9d9b8 solid">
                                    <WrapItem>月間再生回数 : </WrapItem>
                                    <Spacer />
                                    <WrapItem>{num2ja(val.viewsCountMonthDiff)}回</WrapItem>
                                 </Wrap>
                                 <Wrap spacing="0" mt="0.3rem" borderBottom="1px #d9d9d9b8 solid">
                                    <WrapItem>アップ日付 : </WrapItem>
                                    <Spacer />
                                    <WrapItem>{val.updateTime}</WrapItem>
                                 </Wrap>
                                 <Wrap spacing="0" mt="0.3rem" borderBottom="1px #d9d9d9b8 solid">
                                    <WrapItem>日間比較日付 : </WrapItem>
                                    <Spacer />
                                    <WrapItem>{val.dayDiffTarget}</WrapItem>
                                 </Wrap>
                                 <Wrap spacing="0" mt="0.3rem" borderBottom="1px #d9d9d9b8 solid">
                                    <WrapItem>週間比較日付 : </WrapItem>
                                    <Spacer />
                                    <WrapItem>{val.weekDiffTarget}</WrapItem>
                                 </Wrap>
                                 <Wrap spacing="0" mt="0.3rem" borderBottom="1px #d9d9d9b8 solid">
                                    <WrapItem>月間比較日付 : </WrapItem>
                                    <Spacer />
                                    <WrapItem>{val.monthDiffTarget}</WrapItem>
                                 </Wrap> */}
                              </Box>
                           </Box>
                        </Box>
                        
                        </>
                     )
                  }))
                  }
               </Box>
               {/* <Pagenavi1 
                  params={params} 
                  pagecounts={dbhitsCount.pagecounts} 
                  pager={pager}
                  setPager={setPager}
                  handleclick={reflash} 
               /> */}
               <Pagenavi2 
                  params={params} 
                  pagecounts={dbhitsCount.pagecounts} 
                  pager={pager}
                  setPager={setPager}
                  handleclick={reflash} 
               />
               </>
            :
               <Box>
                  <Text>取得済みの動画データがありません</Text>
               </Box>
         :
            <>
            <Box pt="0.4rem">
               {
               React.Children.toArray([...Array(10)].map(() => {
                  return (
                     <Box borderBottom="1px solid #e0e0e0" px="1rem" pb="0.5rem">
                        <Box mt="1rem">
                           <Skeleton ml="1rem" height="1.4rem">SkeletonText</Skeleton>
                        </Box>

                        <Box p="0.5rem" display={["block","flex"]}>
                           <Center px="1.5rem">
                              <Skeleton
                                 w="100%"
                              >
                                 <Image src={"https://via.placeholder.com/320x180"}>

                                 </Image>
                                 </Skeleton>
                           </Center>
                           
                           <Box fontSize="0.8rem" mt="0.5rem" w="100%">
                              <Wrap spacing="0" borderBottom="1px #d9d9d9b8 solid">
                                 <Skeleton h="0.8rem" w="100%">SkeletonText</Skeleton>
                              </Wrap>
                              <Wrap spacing="0" mt="0.3rem" borderBottom="1px #d9d9d9b8 solid">
                                 <Skeleton h="0.8rem" w="100%">SkeletonText</Skeleton>
                              </Wrap>
                              <Wrap spacing="0" mt="0.3rem" borderBottom="1px #d9d9d9b8 solid">
                                 <Skeleton h="0.8rem" w="100%">SkeletonText</Skeleton>
                              </Wrap>
                              <Wrap spacing="0" mt="0.3rem" borderBottom="1px #d9d9d9b8 solid">
                                 <Skeleton h="0.8rem" w="100%">SkeletonText</Skeleton>
                              </Wrap>

                           </Box>
                        </Box>
                     </Box>
                  )
               }))
               }
            </Box>
            <Center p="4rem">
               {/* ページナビ部分 */}
            </Center>
            </>
         }         
         </Box>


         {ownkanrenWords.finished ? 
            ownkanrenWords.kanrenWords.length > 0 ?
               <>
               {React.Children.toArray(ownkanrenWords.kanrenWords.map(val=>{
                  return (
                  <>
                  {val.adimageUrl !== "" ? 
                     <Box m="1rem">
                        <Link as={GatsbyLink} to={val.adUrl}>
                           <AspectRatio w="100%"  ratio={728 / 90}>
                              <Image 
                                 w="100%" 
                                 verticalAlign="bottom" 
                                 src={val.adimageUrl}
                                 fallbackSrc="https://via.placeholder.com/728x90" 
                              />
                           </AspectRatio>
                        </Link>
                     </Box>
                  :
                     ""
                  }
                  </>
                  )
               }))}
               </>
            :
            ""
         :
         ""
         }

         <Box bg="#ddd" mx="0.75rem" p="1rem" mt="1rem">
            <Box mt="1rem">
               <Wrap>
                  <WrapItem>
                     <Switch 
                        onChange={e => setisaddkeyword(e.target.checked)} 
                     />
                  </WrapItem>
                  <WrapItem>
                     <Text alignSelf="center" fontWeight="bold">キーワードを追加</Text>
                  </WrapItem>
               </Wrap>

               {ownkanrenWords.finished ? 
                  ownkanrenWords.kanrenWords.length > 0 ?
                     <>
                     <Midasi3 mb="0rem">ピックアップ</Midasi3>
                     
                        {React.Children.toArray(ownkanrenWords.kanrenWords.map(val=>{
                           return (
                           <>
                           {/*                            
                           <Box>
                              {val.discription}
                           </Box>
                           <Box>
                              <Image src={val.imageUrl} alt="Segun Adebayo" />
                           </Box> */}
                           {React.Children.toArray(val.wordlist.split(",").map(word=>{
                              let key = "";
                              if(params.keyword === ""){
                                 key = word;
                              }else{
                                 if(isaddkeyword){
                                    key = params.keyword + " " + word;
                                 }else{
                                    key = word;
                                 }
                              }
                              return (
                                 <Button 
                                    colorScheme="blue"
                                    borderRadius="0.5rem"
                                    fontSize="0.7rem"
                                    mr="0.6rem"
                                    mt="0.6rem"
                                    px="0.5rem"
                                    height="1.5rem"
                                    onClick={() => {
                                       window.scrollTo(0, 0);
                                       reflash({
                                          ...params,
                                          keyword:key
                                       });
                                    }}
                                 >
                                    {word}
                                 </Button>
                              )
                           }))}
                           </>
                           )
                        }))
                        }
                     </>
                  :
                  ""
               :
               ""
               }

               
               {kanrenWords.finished ? 
                  kanrenWords.kanrenWords.length > 0 ?
                     <>
                     <Midasi3 mb="0rem">関連キーワード</Midasi3>
                     {React.Children.toArray(kanrenWords.kanrenWords.slice(0,100).map(val=>{
                        let key = "";
                        if(params.keyword === ""){
                           key = val[0];
                        }else{
                           if(isaddkeyword){
                              key = params.keyword + " " + val[0];
                           }else{
                              key = val[0];
                           }
                        }
                        return (
                           <Tooltip hasArrow label={val[1]}>
                              <Button 
                                 colorScheme="facebook"
                                 borderRadius="0.5rem"
                                 fontSize="0.7rem"
                                 mr="0.6rem"
                                 mt="0.6rem"
                                 px="0.5rem"
                                 height="1.5rem"
                                 onClick={() => {
                                    window.scrollTo(0, 0);
                                    reflash1({
                                       ...params,
                                       keyword:key,
                                    });
                                 }}
                              >
                                 {val[0]}
                              </Button>
                           </Tooltip>
                        )
                     }))}
                     
                     </>
                  :
                  ""
               :
                  <Box mt="1rem">
                     <Spinner />
                  </Box>
               }
            
               {/* <Box m="0.3rem" fontSize="0.7rem" textAlign="right">
                  {dbhitsCount.finished ? 
                     dbhitsCount.dbhitsCount
                  :
                     <Spinner size="0.7rem"/>
                  }
               </Box> */}
            </Box>
            <Box m="0.3rem" fontSize="0.7rem" textAlign="right">
               {dbhitsCount.finished ? 
                  dbhitsCount.dbhitsCount
               :
                  <Spinner size="0.7rem"/>
               }
            </Box>
         </Box>

         

         <Midasi2>関連ニュース</Midasi2>
         <Box px="0.75rem" display="flex" flexWrap="wrap">
            {kanrenPosts.finished ? 
               kanrenPosts.kanrenPosts.data ?
                  React.Children.toArray(kanrenPosts.kanrenPosts.data.posts.nodes.map((node,index) => {
                  
                     return (
                        <Box bg="#fff" mb="1rem" w="50%" verticalAlign="top" boxSizing="border-box" 
                           borderRight={(index+1)%2 === 0 ?"none":"0.375rem solid #eee"}
                           borderLeft={(index+1)%2 === 0 ?"0.375rem solid #eee":"none"}
                        >
                           <Link as={GatsbyLink} to={node.uri}>
                              <Image 
                                 w="100%" 
                                 verticalAlign="bottom" 
                                 src={node.featuredImage ? node.featuredImage.node.mediaItemUrl : ""}
                                 fallbackSrc="https://via.placeholder.com/337x190" 
                              />
                              <Text
                                 py="1.25rem"
                                 px="1rem"
                                 bg="#fff"
                                 fontSize="1.0rem"
                                 fontWeight="bold"
                                 lineHeight="1.5rem"
                                 boxSizing="border-box"
                                 wordBreak="break-all"
                              >
                                 {node.title}
                              </Text>
                           </Link>
                        </Box>
                     )
                  }))
               :
                  <Box>
                     <Text>該当する関連ニュースはありません</Text>
                  </Box>
            :
               ""
            }

            <Text w="100%"
               rounded="sm"
               fontSize="1.3rem"
               fontWeight="bold"
               lineHeight="4rem"
               textAlign="center"
               color="#fff"
               bg="linkedin.800"
               position="relative"
               _before={{
                  content:'""',
                  display:"block",
                  position:"absolute",
                  top:"1.6rem",
                  right:"2rem",
                  w:"1rem",
                  h:"1rem",
                  borderTop:"0.2rem solid #fff",
                  borderRight:"0.2rem solid #fff",
                  transform:"rotate(45deg)"
               }}
               as={GatsbyLink}
               to="/category/news/1"
            >もっと見る</Text>
         </Box> 
      </Layout>
   )
}
export default VideoList;